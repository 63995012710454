import React, { useState } from 'react'
import { Grid, Stack } from '@mui/material'

// import { useNavigate } from 'react-router-dom'

import { useCurrentUser } from '../../providers/CurrentUserProvider'

const Dashboard = () => {
  const { currentUser } = useCurrentUser()

  // const navigate = useNavigate()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div>Dashboard for logged in users</div>
      </Grid>
    </Grid>
  )
}

export default Dashboard
