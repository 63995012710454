import { createTheme } from '@mui/material/styles'
import { blue, grey, red, green } from '@mui/material/colors'
import '@fontsource/roboto'

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    default: grey[300],
    primary: blue,
    secondary: red,
    background: grey[100],
    positive: green[500],
    spotify: {
      button: green[500],
      buttonHover: green[700],
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
})
