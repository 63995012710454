import React from 'react'

import { useQuery, gql } from '@apollo/client'
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import CameraIcon from '@mui/icons-material/PhotoCamera'
import { useNavigate } from 'react-router-dom'

const GET_PING = gql`
  query getPing {
    ping
  }
`

const Copyright = () => (
  <Typography variant="body2" color="text.secondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://mui.com/">
      Your Website
    </Link>{' '}
    {new Date().getFullYear()}.
  </Typography>
)

const Homepage = () => {
  // const { loading, error, data } = useQuery(GET_PING)
  const navigate = useNavigate()

  const navigateToLogin = () => {
    navigate('/login')
  }

  return (
    <>
      {/* <div>status: {JSON.stringify(data.ping)}</div> */}
      {/* }
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{mr: 2}}/>
          <Typography variant="h6" color="inherit" noWrap>
            Mini Trends
          </Typography>
        </Toolbar>
      </AppBar>
      */}
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
              Mini Trends
            </Typography>
            <Typography variant="h5" align="left" color="text.secondary" paragraph>
              Mini Trends provides custom software solutions that drive business growth and success.
            </Typography>
            <Typography variant="paragraph" align="left" color="text.secondary" paragraph>
              We understand that no two businesses are the same, which is why we specialize in creating custom software solutions tailored
              to meet your specific needs. Our team of expert developers works tirelessly to ensure that our solutions align with your
              business goals and provide the best possible return on investment.
            </Typography>
            <Typography variant="paragraph" align="left" color="text.secondary" paragraph>
              Whether you need a new software system to streamline your business operations or require custom software to give you a
              competitive edge, we have the expertise and experience to deliver results. Our solutions are designed to improve efficiency,
              reduce costs, and boost your bottom line.
            </Typography>
            <Typography variant="paragraph" align="left" color="text.secondary" paragraph>
              We work closely with you every step of the way, from initial consultation to final deployment and beyond. We strive to exceed
              your expectations and deliver exceptional results every time.
            </Typography>
            <Stack sx={{ pt: 4 }} direction="row" spacing={2} justifyContent="center">
              <Button variant="contained" onClick={navigateToLogin}>
                Client Login
              </Button>
              {/* <Button variant="outlined">Secondary action</Button> */}
            </Stack>
          </Container>
        </Box>
      </main>
      {/* Footer */}
      {/* }
      <Box sx={{bgcolor: 'background.paper', p: 6}} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
        <Copyright/>
      </Box>
      */}
      {/* End footer */}
    </>
  )
}

export default Homepage
