import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { gql, useLazyQuery } from '@apollo/client'
import { get } from 'lodash-es'

export const CurrentUserContext = React.createContext()

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      userId
      email
      fullName
      socialMediaUsernames {
        source
        username
      }
    }
  }
`

const CurrentUserProvider = (props) => {
  const { children } = props

  const [currentUser, setCurrentUser] = useState(null)
  const [isCurrentUserLoaded, setIsCurrentUserLoaded] = useState(false)

  const [getCurrentUser, { called, loading, data }] = useLazyQuery(GET_CURRENT_USER)
  // console.log('CurrentUserProvider > useLazyQuery result:', { called, loading, error, data })

  useEffect(() => {
    if (called && !loading) {
      const output = get(data, 'getCurrentUser')
      if (output) {
        setCurrentUser(output)
      }
      setIsCurrentUserLoaded(true)
    }
  }, [called, loading, data])

  const contextValue = useMemo(
    () =>
      // console.log('CurrentUserProvider > useMemo > contextValue > currentUser:', currentUser)
      ({ currentUser, isCurrentUserLoaded, getCurrentUser }),
    [currentUser, isCurrentUserLoaded]
  )

  return <CurrentUserContext.Provider value={contextValue}>{children}</CurrentUserContext.Provider>
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node,
}

CurrentUserProvider.defaultProps = {
  children: null,
}

export default CurrentUserProvider

export const useCurrentUser = () => React.useContext(CurrentUserContext)
