import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'

import CssBaseline from '@mui/material/CssBaseline'

import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import reportWebVitals from './reportWebVitals'

import { getJwtTokenFromCookie } from './lib/authentication/cookie'

import theme from './themes/default'

import router from './routes'
import CurrentUserProvider from './providers/CurrentUserProvider'

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_SERVER_ROOT_URL}/graphql`,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const setAuthorizationLink = new ApolloLink((operation, forward) => {
  const headers = {}
  const token = getJwtTokenFromCookie()
  operation.setContext({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  })
  return forward(operation)
})

const client = new ApolloClient({
  name: 'minitrends-web-client',
  version: '1.0',
  cache: new InMemoryCache(),
  link: from([errorLink, setAuthorizationLink, httpLink]),
  defaultOptions: {
    query: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  },
})

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <CssBaseline />
    <ApolloProvider client={client}>
      <CurrentUserProvider>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </CacheProvider>
      </CurrentUserProvider>
    </ApolloProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
