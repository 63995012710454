import React from 'react'

import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import App from './components/App'
import Dashboard from './components/Dashboard'
import Homepage from './components/Homepage'
import Login from './components/Login'

import NotFound from './components/NotFound'
import PrivateRoute from './components/PrivateRoute'

const routes = createRoutesFromElements(
  <Route path="/" element={<App />}>
    <Route path="/" element={<Homepage />} />
    <Route path="/login" element={<Login />} />
    <Route path="*" element={<NotFound />} />
    <Route element={<PrivateRoute />}>
      <Route path="/dashboard" element={<Dashboard />} />
    </Route>
  </Route>
)

const router = createBrowserRouter(routes)

export default router
