import React, { useState, useEffect } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../providers/CurrentUserProvider'

const PrivateRoute = () => {
  const [authenticated, setAuthenticated] = useState(null)
  const { currentUser, isCurrentUserLoaded, getCurrentUser } = useCurrentUser()

  const navigate = useNavigate()

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  useEffect(() => {
    if (isCurrentUserLoaded) {
      setAuthenticated(!!currentUser) // double bang returns the true/false value of a non-boolean variable
    }
  }, [currentUser, isCurrentUserLoaded])

  if (authenticated === false) {
    return navigate('/login')
  }

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default PrivateRoute
